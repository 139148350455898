<template>
  <button
    class="button"
    :disabled="disabled"
    :variant="variant"
    :class="{ small }"
    @click="onClick"
  >
    <UPreloader v-if="loading" size="30" />
    <slot />
  </button>
</template>

<script>
import UPreloader from '@/components/redesign/ui/UPreloader';

export default {
  name: 'UButton',
  components: { UPreloader },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  height: 44px;
  color: #fff;
  background-color: #0088CC;
  border: none;
  padding: 0 1rem;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  border-radius: 8px;

  .icon {
    fill: #0088CC ;
  }

  &:focus {
    outline: none;
  }

  &.small {
    height: 32px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &[variant='gray'] {
    background: #dbdbdb;
    color: #000;

    .icon {
      margin-right: 0.5rem;
      fill: #aeaeae;
    }
  }

  &[variant='soft'] {
    background: #E6F3FA;
    color: #0088CC;
  }

  &[variant='yellow'] {
    background: #CC9F00;
  }

  &[disabled] {
    pointer-events: none;
    background-color: #f0f0f0;
    color: #909090;
  }
}
</style>
