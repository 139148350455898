<template>
  <div class="preloader">
    <div class="loader" :style="{ width: `${size}px` }">
      <svg class="circular" viewBox="25 25 50 50">
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UPreloader',
  props: {
    size: {
      type: Number,
      default: 60,
    },
  },
  computed: {
    strokeWidth() {
      if (this.size < 40) return 3;
      return 2;
    },
  },
};
</script>
<style scoped lang="scss">
.preloader {
  .loader {
    position: relative;
    margin: 0 auto;

    .circular {
      -webkit-animation: rotateloader 2s linear infinite;
      animation: rotateloader 2s linear infinite;
      height: 100%;
      -webkit-transform-origin: center center;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      .path {
        stroke-dasharray: 2, 200;
        stroke-dashoffset: 0;
        -webkit-animation: dashloader 1.5s ease-in-out infinite,
          color 6s ease-in-out infinite;
        animation: dashloader 1.5s ease-in-out infinite,
          color 6s ease-in-out infinite;
        stroke-linecap: round;
        stroke: #000;
      }
    }

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}

@-webkit-keyframes rotateloader {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes dashloader {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
