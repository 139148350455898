<template>
  <transition name="modal-fade">
    <div v-show="value" class="modal-doctis" :class="type">
      <div class="overlay" @click="closeModal"></div>
      <div
        class="modal-wrapper custom-scrollbar"
        :style="{ width: `${width}` }"
      >
        <div v-if="close" class="close" @click="closeLockModal">
          <UIcon name="close" />
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '600px'
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    lock: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value() {
      if (this.value) {
        document.body.classList.add('open-modal');
      } else {
        document.body.classList.remove('open-modal');
      }
    }
  },
  methods: {
    closeModal() {
      if (!this.lock) {
        this.closeLockModal();
      }
    },
    closeLockModal() {
      this.$emit('input', !this.value);
    }
  }
};
</script>

<style lang="scss">
.modal-doctis {
  .header-mobile {
    display: none;
  }

  &.full-content-mobile {
    @media only screen and (max-width: 768px) {
      .modal-wrapper {
        min-height: 100% !important;
        min-width: 100%;
        border-radius: 0;
        padding-top: 0;
      }

      .support-browser {
        padding-top: 0;
      }

      .close {
        display: none;
      }

      .header-mobile {
        display: flex;
        align-items: center;
        height: 48px;
        border-bottom: 1px solid #e5e5e5;

        .title {
          width: 100%;
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
}

.modal-doctis {
  position: fixed;
  z-index: 1300;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: Muller, sans-serif;
  overflow: auto;
  cursor: auto;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #0c0c0c;
    opacity: 0.4;
  }

  .modal-wrapper {
    overflow: auto;
    position: relative;
    background: #fff;
    padding: 16px;
    border: 1px solid #e5e5e5;
    box-shadow: none;
    border-top: 15px solid #fff;
    border-bottom: 15px solid #fff;
    border-radius: 24px;
    z-index: 1080;
    width: 600px;
    max-height: 80%;
    @media (max-width: 1200px) {
      width: 700px;
    }

    @media (max-width: 992px) {
      width: calc(100vw - 30px) !important;
    }

    > span.title {
      display: block;
      @media (max-width: 992px) {
        text-align: center;
      }
    }

    .close {
      position: absolute;
      top: 5px;
      right: 16px;
      font-size: 25px;
      cursor: pointer;
      background: #E6F3FA;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      fill: #0088CC;
      z-index: 1000;

      .icon {
        width: 11px;
        height: 11px;
      }

      &:hover {
        background: #D9F2FF;
      }
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
  pointer-events: none;
}

@media (max-width: 576px) {
  .modal-doctis {
    align-items: flex-end;

    .modal-wrapper {
      width: 100% !important;
      border-radius: 24px 24px 0 0;
      max-height: 95%;
      border-bottom: 0;
    }
  }
}
</style>
