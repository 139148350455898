<template>
  <div class="form-input">
    <input
      :id="id"
      v-model="inputValue"
      :type="type"
      :disabled="disabled"
      placeholder=" "
    />
    <label :for="id" class="text-sm">{{ placeholder }}</label>
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  width: 100%;
  display: flex;
  flex-flow: column-reverse;
  border-bottom: 1px solid #E5E7EB;

  label,
  input {
    transition: all 0.25s;
    touch-action: manipulation;
    font-size: 16px;
    margin: 0;
  }

  input {
    border: none;
    background: none;
    font-family: inherit;
    width: 100%;
    font-size: 16px;
    color: #000;
    padding: 10px 0;
    transition: all ease-in-out 0.2s;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }

    &[disabled] {
      background: #fff;
    }
  }

  label {
    color: #a8a8a8;
    font-size: 14px;
  }

  input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 1.9rem);
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(0, 0);
    cursor: pointer;
  }
}
</style>
