<template>
  <div class="employee-card" @click="chose">
    <div class="avatar-employee">{{ employee.initials }}</div>
    <div class="info">
      <div class="name">{{ employee.name }}</div>
      <div class="email">{{ employee.email }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeCard',
  props: {
    employee: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    chose() {
      this.$router.replace(`/employees/${this.employee.id}`)
    }
  },
};
</script>

<style lang="scss">
.employee-card {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px 12px;

  .avatar-employee {
    width: 50px;
    height: 50px;
    background: #E6F3FA;
    color: #0088CC;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 500;
    margin-right: 16px;
  }

  .info {
    .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    .email {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
