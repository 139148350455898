<template>
  <div class="create-employee">
    <header>
      <div class="title">Создание сотрудника</div>
    </header>

    <Input
      v-model="employee.name"
      id="name"
      placeholder="Имя*"
    />

    <Input
      v-model="employee.surname"
      id="surname"
      placeholder="Фамилия"
    />

    <Input
      v-model="employee.phone"
      id="phone"
      placeholder="Телефон"
    />

    <Input
      v-model="employee.email"
      id="email"
      placeholder="Email*"
    />

    <Input
      v-model="employee.password"
      id="password"
      type="password"
      placeholder="Пароль*"
    />

    <UButton @click="add">
      Добавить сотрудника
    </UButton>
  </div>
</template>

<script>
import { coreApi } from '@/api'
import { mapState } from 'vuex'
import Input from '@/components/redesign/form/FormInput';
import UButton from '@/components/redesign/ui/UButton';

export default {
  name: 'CreateEmployee',
  components: {
    Input,
    UButton,
  },
  data() {
    return {
      employee: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapState('main', ['user']),
  },
  methods: {
    create() {
      this.$emit('create');
    },
    async add() {
      const { name, email, password } = this.employee;
      if (!(name || email || password)) {
        this.$toast.error("Имя, email и пароль обязательные поля");
        return;
      }

      await coreApi.employeesAdd([{
        ...this.employee,
        userId: this.user.id,
      }]);

      this.$emit('create');
    },
  },
};
</script>

<style lang="scss">
.create-employee {
  width: 100%;
  padding: 0 10px;

  header {
    margin-bottom: 40px;

    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 18px;
    }
  }

  & > .form-input {
    margin-bottom: 24px;
  }
}
</style>
