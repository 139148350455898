<template>
  <div class="employees">
    <header>
      <div class="search">
        <UIcon name="search" />
        <input type="text" v-model="search" placeholder="Поиск по сотрудникам" />
      </div>
      <UButton @click="isModalCreateEmployee = true">
        Добавить сотрудника
      </UButton>
    </header>

    <div v-if="employees.length" class="wrapper">
      <Employee
        v-for="(employee, index) of employees"
        :key="index"
        :employee="employee"
      />
    </div>
    <div v-else class="empty">
      <UIcon name="illustrations/empty-data" />
      <div class="title">Сотрудники не найдены</div>
    </div>

    <UModal v-model="isModalCreateEmployee" width="400px" close>
      <CreateEmployee @create="createEmployee" />
    </UModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Employee from "@/components/redesign/employees/EmployeeCard";
import CreateEmployee from "@/components/redesign/employees/CreateEmployee";
import UButton from '@/components/redesign/ui/UButton';
import UModal from '@/components/redesign/ui/UModal';

export default {
  name: 'Employees',
  components: {
    Employee,
    UButton,
    CreateEmployee,
    UModal,
  },
  data() {
    return {
      search: [],
      isModalCreateEmployee: false,
    };
  },
  computed: {
    ...mapState('courses', ['employees']),
  },
  methods: {
    ...mapActions('courses', ['init']),
    createEmployee() {
      this.init();
      this.isModalCreateEmployee = false;
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss">
.employees {
  width: 100%;

  header {
    display: flex;
    margin-bottom: 24px;

    .search {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 48px;
      background: #FFFFFF;
      border-radius: 8px;
      width: 100%;
      margin-right: 16px;

      .icon {
        fill: #A9A9A9;
        margin-right: 24px;
      }

      input {
        height: 100%;
        padding: 0;
        border: none;
        font-size: 16px;
      }
    }

    & > .button {
      max-width: 230px;
    }
  }

  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .employee-card {
      cursor: pointer;
      transition: .2s;

      &:hover {
        box-shadow: 0 12px 13px hsl(0deg 0% 77% / 25%);
      }
    }
  }

  & > .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0 0;

    .title {
      font-size: 16px;
      margin-top: 50px;
      color: #666666;
    }
  }
}
</style>